import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SbEditable from 'storyblok-react';

const GreenHouseEmbed = ({ content }) => {
const [isLoading, setIsLoading] = useState(true);
const [currentTime, setCurrentTime] = useState(new Date());
const [timeUpdated, setTimeUpdated] = useState(false);
const formattedTime = encodeURIComponent(currentTime.toISOString());
const unixTime = Math.floor(Date.now() / 1000);

const originalUrl = 'https://boards.greenhouse.io/embed/job_board/js?for=fulcrumglobaltechnologies';
const careersUrl = `https://boards.greenhouse.io/embed/job_board/js?time=${formattedTime}&for=fulcrumglobaltechnologies`;
  

  useEffect(() => {
      const queryString = window.location.search;
      const params = new URLSearchParams(queryString);
      const gh_jid = params.get('gh_jid');

      if(gh_jid != null)
      {
          const element = document.getElementById("grnhse_app");
          element.scrollIntoView();
      }
  }, [content.Embed]);

  return (
    <SbEditable content={content}>
      <GreenHouse>
        <div id="grnhse_app">
             <script type="text/javascript" src="/assets/jobBoard.js"></script>
        </div>
      </GreenHouse>
    </SbEditable>
  );
};


export default GreenHouseEmbed;

const GreenHouse = styled.section`
  padding: 0;
`;

const LoadingMessage = styled.div`
  text-align: center;
  font-size: 16px;
  color: #333;
  margin-top: 20px;
`;

GreenHouseEmbed.propTypes = {
  content: PropTypes.shape({
    Embed: PropTypes.string,
  }),
};

GreenHouseEmbed.defaultProps = {
  content: {
    Embed: null,
  },
};
