import React from 'react';
import PropTypes from 'prop-types';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
//import 'react-awesome-slider/dist/styles.css';
import AwesomeSliderStyles from "react-awesome-slider/dist/styles.css?raw";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const Awesome = ({
  children,
  play,
  cancelOnInteraction,
  interval
}) => {
  return (
    <AutoplaySlider
      play={play}
      cancelOnInteraction={cancelOnInteraction} // should stop playing on user interaction
      interval={interval}
    >
      {children.length &&
        children.map((child) => (
          <div key={child.key}>{child}</div>
        ))}
    </AutoplaySlider>
    );
};

export default Awesome;

Awesome.PropTypes = {
  children: PropTypes.node,
  play: PropTypes.bool,
  cancelOnInteraction:  PropTypes.bool,
  interval: PropTypes.number,
};

Awesome.defaultProps = {
  children: null,
  play: true,
  cancelOnInteraction: false,
  interval: 6000,
};
