import ComponentMissing from './ComponentMissing';

import Banners from '../components/Banners';
import Button from '../components/Button';
import Carousels from '../components/Carousels';
import ColumnedTextBlock from '../components/ColumnedTextBlock';
import ColumnedTextBlockWithHeadline from '../components/ColumnedTextBlockWithHeadline';
import CopyWithImageSlider from '../components/CopyWithImageSlider';
import FiftyFiftyTextBlock from '../components/FiftyFiftyTextBlock';
import FullBleedImage from '../components/FullBleedImage';
import FullWidthImage from '../components/FullWidthImage';
import FullWidthImageWithCTA from '../components/FullWidthImageWithCTA';
import GreenHouseEmbed from '../components/GreenHouseEmbed';
import HeaderBlock from '../components/HeaderBlock';
import Hubspot from '../components/Hubspot';
import HubspotEmbed from '../components/HubspotEmbed';
import IconListWithImage from '../components/IconListWithImage';
import ImageColumns from '../components/ImageColumns';
import IntroTextBlock from '../components/IntroTextBlock';
import LargeQuoteTextBlock from '../components/LargeQuoteTextBlock';
import LargeSpaciousTextBlock from '../components/LargeSpaciousTextBlock';
import Page from '../components/Page';
import ProcessHorizontal from '../components/ProcessHorizontal';
import ProcessVertical from '../components/ProcessVertical';
import QuoteTextBlock from '../components/QuoteTextBlock';
import RapidxEcosystem from '../components/RapidxEcosystem';
import RolloverPills from '../components/RolloverPills';
import RolloverPillsCTA from '../components/RolloverPillsCTA';
import RolloverPillsThreeUp from '../components/RolloverPillsThreeUp';
import RolloverPillsFixTotal from '../components/RolloverPillsFixTotal';
import ShapeInject from '../components/ShapeInject';
import Spacer from '../components/Spacer';
import SquareCarousel from '../components/SquareCarousel';
import TextBlock from '../components/TextBlock';
import TextImageSplitBlock from '../components/TextImageSplitBlock';
import TextNoPadding from '../components/TextNoPadding';
import TextWithCTA from '../components/TextWithCTA';
import Video from '../components/Video';
import Vimeo_Size from '../components/Vimeo_Size';


//

const AvailableComponents = {
  Banners,
  Button,
  Carousels,
  ColumnedTextBlock,
  ColumnedTextBlockWithHeadline,
  CopyWithImageSlider,
  FiftyFiftyTextBlock,
  FullBleedImage,
  FullWidthImage,
  FullWidthImageWithCTA,
  GreenHouseEmbed,
  HeaderBlock,
  Hubspot,
  HubspotEmbed,
  IconListWithImage,
  ImageColumns,
  IntroTextBlock,
  LargeQuoteTextBlock,
  LargeSpaciousTextBlock,
  ProcessHorizontal,
  ProcessVertical,
  QuoteTextBlock,
  RapidxEcosystem,
  RolloverPillsCTA,
  RolloverPillsData: RolloverPills,
  RolloverPillsImage: RolloverPills,
  RolloverPillsThreeUp,
  RolloverPillsDataFixTotal: RolloverPillsFixTotal,
  ShapeInject,
  Spacer,
  SquareCarousel,
  TextBlock,
  TextImageSplitBlock,
  TextNoPadding,
  TextWithCTA,
  Video,
  Vimeo_Size,
  page: Page,
  
};

//

const StoryblokComponents = (type) => {
  if (typeof AvailableComponents[type] === 'undefined') {
    return ComponentMissing;
  }

  return AvailableComponents[type];
};

export default StoryblokComponents;
