import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Vimeo from '@u-wave/react-vimeo';
import getVideoId from 'get-video-id';
import SbEditable from 'storyblok-react';

import Container from '../Container';
import ColorPicker from '../../helpers/ColorPicker';

const Vimeo_Size = ({ content }) => {
  if (!content.VimeoURL) return null;

  const { id } = getVideoId(content.VimeoURL);

  const theme = ColorPicker(content.BgColor, false);

  let width  = content.width;
  let height = content.height;

  return (
    <SbEditable content={content}>
      <VideoWrap  themeBackground={theme.background}>
          <Vimeoframe video={id} responsive autoplay />
      </VideoWrap>
    </SbEditable>
  );
};

export default Vimeo_Size;

const Vimeoframe = styled(Vimeo)`
  position: relative;
  margin:auto;
  width: calc(0.5*100vw);

  @media only screen and (max-width: 768px), only screen and (max-device-width: 768px) {
    width:100%;
  }
  @media only screen and (max-width: 240px), only screen and (max-device-width: 240px) {
    width:100%;
  }
`;

const VideoWrap = styled.section`
  padding:4rem;
  margin:auto;

  background-color: var(--${(props) => props.themeBackground});
  @media (min-width: 640px) {
    padding:2rem;
  }


  @media only screen and (max-width: 768px), only screen and (max-device-width: 768px) {
    padding:2rem;
  }
  @media only screen and (max-width: 240px), only screen and (max-device-width: 240px) {
    padding:2rem;
  }

`;

Vimeo_Size.propTypes = {
  content: PropTypes.shape({
    BgColor: PropTypes.string,
    VimeoURL: PropTypes.string,
     width: PropTypes.string,
    height: PropTypes.string
  }),
};

Vimeo_Size.defaultProps = {
  content: {
    BgColor: 'grey--0',
    VimeoURL: null,
    width:null,
    height:null
  },
};
