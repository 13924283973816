import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BsArrowDownShort } from 'react-icons/bs';
import Img from 'gatsby-image';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import { Link } from 'gatsby';

import Icon from '../Icon';
import Shape from '../Shape';
import Button from '../Button';
import Richtext from '../../helpers/Richtext';

//

const Carousel = ({ content, theme }) => {
  if (!content.Link) return null;

  let fluidProps = null;
  if (content.Image && content.Image.id) {
    fluidProps = getFluidGatsbyImage(content.Image.filename, {
      maxWidth: 1080,
    });
  }

  const PillContent = () => (
    <>
          <div className="content">
            {content.Subtitle && <h3>{content.Subtitle}</h3>}
            {content.Headline && <h2>{content.Headline}</h2>}
            {content.Copy && <p>{content.Copy}</p>}
            {content.Button && content.Button.length > 0 && (
              <Button
                content={{
                  label: content.Button[0].label,
                  to: content.Button[0].to,
                  arrow: content.Button[0].arrow,
                }}
                customTheme={theme}
              />
            )}
          </div>

          <div className="image">
            <div className="image--wrap">
              <Img fluid={fluidProps} alt={content.Image.alt} />
              {content.ImageBackgroundShape && (
                <BgShape
                  shape={content.ImageBackgroundShape}
                  color={theme.highlight}
                />
              )}
            </div>
          </div>
      </>
  );

  return (
    <CarouselWrap
      themeBackground={theme.background}
      themeDivider={theme.divider}
      themeText={theme.text}
      themeHighlight={theme.highlight}
      squared={content.Squared}
      className={`${content.Description ? 'hasDesc' : ''}`}
    >
      {content.Link.cached_url !== '' && content.Link.linktype === 'story' && (
        <Link to={`/${content.Link.cached_url}`}>
          <PillContent />
        </Link>
      )}
      {content.Link.url !== '' && content.Link.linktype === 'url' && (
        <a href={content.Link.url} target="_blank" rel="noreferrer noopener">
          <PillContent />
        </a>
      )}

      {!content.Link.url && !content.Link.cached_url && <PillContent />}
    </CarouselWrap>
  );
};

export default Carousel;

const CarouselWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  direction: ${(props) => (props.flip ? 'rtl' : 'ltr')};
  width: 100%;

  > * {
    direction: ltr;
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }

  a {
    display: flex;
    align-items: stretch;
    color: var(--${(props) => props.themeText});
    text-decoration: none;
  }

  &.hasDesc,
  &.hasDesc a {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  background-color: var(--${(props) => props.themeBackground});
  color: var(--${(props) => props.themeText});

  @media (min-width: 640px) {
    /*padding: 4.2rem;*/
  }

  .content {
    width: 100%;

    @media (min-width: 768px) {
      width: 50%;
    }

    h3 {
      margin-bottom: 1.4rem;
      color: var(--${(props) => props.themeHighlight});
      font-size: 1.4rem;
      line-height: 2rem;
    }

    h2 {
      font-family: var(--font-serif);
      font-size: 3.2rem;
      line-height: 3.8rem;

      @media (min-width: 1024px) {
        font-size: 4.2rem;
        line-height: 4.8rem;
      }
    }

    p {
      margin-top: 2.4rem;
      font-size: 1.4rem;
      line-height: 1.8rem;

      @media (min-width: 1024px) {
        font-size: 1.8rem;
        line-height: 2.4rem;
      }
    }

    button {
      margin-top: 6rem;
    }
  }

  .image {
    width: 100%;
    margin-top: 2.4rem;

    @media (min-width: 768px) {
      width: calc(50% - 3.2rem);
      margin-top: unset;
    }

    .gatsby-image-wrapper {
      position: relative;
      z-index: 15;
    }

    .image--wrap {
      position: relative;
    }
  }
`;

const BgShape = styled(Shape)`
  display: flex;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0.1;
`;

Carousel.propTypes = {
  content: PropTypes.shape({
    Icon: PropTypes.string,
    Title: PropTypes.string,
    Subtitle: PropTypes.string,
    Description: PropTypes.string,
    Image: PropTypes.object,
    Link: PropTypes.object,
  }),

  theme: PropTypes.shape({
    background: PropTypes.string,
    divider: PropTypes.string,
    text: PropTypes.string,
    highlight: PropTypes.string,
  }),
};

Carousel.defaultProps = {
  content: {
    Icon: null,
    Title: 'Lorem ipsum...',
    Subtitle: null,
    Description: null,
    Image: null,
    Link: null,
  },

  theme: {
    background: null,
    divider: null,
    text: null,
    highlight: null,
  },
};
