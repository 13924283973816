import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BsArrowDownShort } from 'react-icons/bs';
import Img from 'gatsby-image';
//import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import { Link } from 'gatsby';

import Icon from '../Icon';
import Shape from '../Shape';
import Button from '../Button';
import Richtext from '../../helpers/Richtext';

//

const Banner = ({ content, theme }) => {

  const name = content.Media.filename;
  const ext  = name.slice(-4);

  const pc = (() => {
  if (ext == '.mp4') {
    return <video preload='auto' width='100%' height='100%' loop autoPlay muted><source src={content.Media.filename} type='video/mp4' />Your browser does not support HTML5 video.</video>;
  } else if (ext == '.jpg') {
      let fluidProps = null;
      //  if (content.Media && content.Media.id) {
      //  fluidProps = getFluidGatsbyImage(content.Media.filename, {
      //    maxWidth: 1080,
      //  });
      //}
    return <div className='image'><img src={content.Media.filename} alt={content.Media.alt} imgStyle={{ objectFit: 'cover' }} style={{ width: '100%', height: 'auto!important' }} /></div>;
  }
})();

  const PillContent = () => (
      <>
      {pc}
        </>
    );

  return (
      <PillContent />
  );
};

export default Banner;

const BannerWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  direction: ${(props) => (props.flip ? 'rtl' : 'ltr')};
  width: 100%;

  > * {
    direction: ltr;
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }

  a {
    display: flex;
    align-items: center;
    color: var(--${(props) => props.themeText});
    text-decoration: none;
  }

  &.hasDesc,
  &.hasDesc a {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  background-color: var(--${(props) => props.themeBackground});
  color: var(--${(props) => props.themeText});

  @media (min-width: 640px) {
    /*padding: 4.2rem;*/
  }

  .content {
    width: 100%;

    @media (min-width: 768px) {
      width: 50%;
    }

    h3 {
      margin-bottom: 1.4rem;
      color: var(--${(props) => props.themeHighlight});
      font-size: 1.4rem;
      line-height: 2rem;
    }

    h2 {
      font-family: var(--font-serif);
      font-size: 3.2rem;
      line-height: 3.8rem;

      @media (min-width: 1024px) {
        font-size: 4.2rem;
        line-height: 4.8rem;
      }
    }

    p {
      margin-top: 2.4rem;
      font-size: 1.4rem;
      line-height: 1.8rem;

      @media (min-width: 1024px) {
        font-size: 1.8rem;
        line-height: 2.4rem;
      }
    }

    button {
      margin-top: 6rem;
    }
  }

  .image {
    width: 100%;
    margin-top: 2.4rem;

    @media (min-width: 768px) {
      width: calc(50% - 3.2rem);
      margin-top: unset;
    }

    .gatsby-image-wrapper {
      position: static!important
    }
  }

  image{
    width:  100%;
    height: auto !important;
  }

  .video-wrapper{
    width: 100%;
  }

  video{
    width: 100%;
    height auto!important;
    max-width:540px;
  }
`;

Banner.propTypes = {
  content: PropTypes.shape({
    Image: PropTypes.object,
    Video: PropTypes.object,
  }),

  theme: PropTypes.shape({
    background: PropTypes.string,
    divider: PropTypes.string,
    text: PropTypes.string,
    highlight: PropTypes.string,
  }),
};

Banner.defaultProps = {
  content: {
    Image: null,
    Video: null,
  },

  theme: {
    background: null,
    divider: null,
    text: null,
    highlight: null,
  },
};
